const breakpoints = ["425px", "575px", "768px", "1024px", "1440px"]

// aliases
breakpoints.xs = breakpoints[0]
breakpoints.sm = breakpoints[1]
breakpoints.md = breakpoints[2]
breakpoints.lg = breakpoints[3]
breakpoints.xl = breakpoints[4]

export default {
  breakpoints,
  fonts: {
    heading: "inherit",
    monospace: "Menlo, monospace",
  },
}
