import React from "react"
import styled from "styled-components"
import { size } from "./breakpoints"
import { Box, Card, Heading, Text } from "rebass"

export const Aligned = styled.div`
  position: relative;
  margin-bottom: 500px;
  @media only screen and (max-width: ${size.tablet}) {
    margin-bottom 350px;
  }
  @media only screen and (max-width: ${size.tabletS}) {
    margin-bottom: 150px;
  }
`
const Image = styled.div`
  top: 0px;
  z-index: 2;
  position: absolute;
  width: 450px;
  overflow: visible;
  @media only screen and (max-width: ${size.tablet}) {
    width: 300px;
  }
  @media only screen and (max-width: ${size.tabletS}) {
    margin: 70px auto 0 auto;
    width: 275px;
    position: relative;
  }
`

export const RightAlignedImage = styled(Image)`
  right: 0;
`

export const LeftAlignedImage = styled(Image)`
  left: 0;
`

export const HalfScreenWrapper = styled.div`
  width: 40%;
  @media only screen and (max-width: ${size.tabletS}) {
    width: 100%;
  }
`

export const LeftHalfWrapper = styled(HalfScreenWrapper)`
  margin-left: auto;
`
export const RightHalfWrapper = styled(HalfScreenWrapper)`
  margin-right: auto;
`

export const CardDeckContainer = styled.div``
export const CardContainer = styled.div`
  display: grid;
  grid-column: ${props => props.location} / ${props => props.location + 1};
`

export const Margin = styled.div`
  margin-bottom: 130px;
  @media only screen and (max-width: ${size.tablet}) {
    margin-bottom: 90px;
  }
  @media only screen and (max-width: ${size.tabletS}) {
    margin-bottom: 0;
  }
`

export const RebassCard = ({ icon, title, children, variant, sal }) => (
  <Box
    width={[1, 1 / 2, 1 / 2, 1 / 3]}
    p={3}
    data-sal={sal?.mode ? sal.mode : null}
    data-sal-duration={sal?.duration ? sal.duration : null}
    data-sal-delay={sal?.delay ? sal.delay : null}
    data-sal-easing={sal?.easing ? sal.easing : null}
  >
    <StyledCard
      variant={variant}
      backgroundColor={"#f8f8f8"}
      sx={{
        padding: 20,
        borderRadius: 20,
        height: "100%",
      }}
    >
      <Box px={2} sx={{ height: "100%" }}>
        {icon ? (
          <Heading
            color={"rgba(226,68,8,0.92)"}
            fontSize={[6, 6, 6, 7]}
            mt={2}
            mb={3}
            fontFamily="monospace"
          >
            {icon}
          </Heading>
        ) : null}
        {title ? (
          <Heading fontFamily="monospace" mb={3}>
            {title}
          </Heading>
        ) : null}
        <Text
          fontSize={16}
          sx={{
            marginTop: "auto",
          }}
        >
          {children}
        </Text>
      </Box>
    </StyledCard>
  </Box>
)

const StyledCard = styled(Card)`
  :hover {
    ${props =>
      props.variant
        ? `background-color: ${`#ff6311`}; color: ${`#fbfbfb`}`
        : `background-color: ${`#fbfbfb`}`}
  }
  :hover * {
    ${props =>
      props.variant
        ? `background-color: ${`#ff6311`}; color: ${`#fbfbfb`}`
        : `background-color: ${`#fbfbfb`}`}
  }
`
